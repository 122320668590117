import React, {useEffect, useRef} from 'react';
import './Projects.css'
import Header from '../Header/Header'
import GetInTouch from '../GetInTouch/GetInTouch'
import Footer from '../Footer/Footer';
import hoverEffect from 'hover-effect';
import image9 from '../Assets/project9.jpg'
import image1 from '../Assets/project1.jpg'
import image2 from '../Assets/project2.jpg'
import image3 from '../Assets/project3.jpg'
import image4 from '../Assets/project4.jpg'
import image5 from '../Assets/project5.jpg'
import image6 from '../Assets/project6.jpg'
import image7 from '../Assets/project7.jpg'
import image8 from '../Assets/project8.jpg'
import displacement from '../Assets/4.png'
import arrow from '../Assets/arrow.svg'
import { Helmet } from 'react-helmet';
import gsap from 'gsap';
function Projects() {
  
  let text1 = useRef(null)
    const timeline_project = gsap.timeline();
    useEffect(()=> {
        timeline_project.from(text1, {
            duration: 1,
            skewY: 10,
            y: 500,
            delay: 2,
            stagger: {
                amount: .4
            },
            opacity: 0
        },"-=1");
    })
  useEffect(()=>{
    var myAnimation = new hoverEffect({
      parent: document.querySelector('.project-page-item-image1'),
      intensity: 0.3,
      image1: image1,
      image2: image1,
      displacementImage: displacement
  });
  var myAnimation1 = new hoverEffect({
    parent: document.querySelector('.project-page-item-image2'),
    intensity: 0.3,
    image1: image2,
    image2: image2,
    displacementImage: displacement
  });
  var myAnimation2 = new hoverEffect({
    parent: document.querySelector('.project-page-item-image3'),
    intensity: 0.3,
    image1: image3,
    image2: image3,
    displacementImage: displacement
  });
  var myAnimation3 = new hoverEffect({
    parent: document.querySelector('.project-page-item-image4'),
    intensity: 0.3,
    image1: image4,
    image2: image4,
    displacementImage: displacement
  });
  var myAnimation4 = new hoverEffect({
    parent: document.querySelector('.project-page-item-image5'),
    intensity: 0.3,
    image1: image5,
    image2: image5,
    displacementImage: displacement
  });
  var myAnimation5 = new hoverEffect({
    parent: document.querySelector('.project-page-item-image6'),
    intensity: 0.3,
    image1: image6,
    image2: image6,
    displacementImage: displacement
  });
  var myAnimation6 = new hoverEffect({
    parent: document.querySelector('.project-page-item-image7'),
    intensity: 0.3,
    image1: image7,
    image2: image7,
    displacementImage: displacement
  });
  var myAnimation7 = new hoverEffect({
    parent: document.querySelector('.project-page-item-image8'),
    intensity: 0.3,
    image1: image8,
    image2: image8,
    displacementImage: displacement
  });
  var myAnimation8 = new hoverEffect({
    parent: document.querySelector('.project-page-item-image9'),
    intensity: 0.3,
    image1: image9,
    image2: image9,
    displacementImage: displacement
  });
})

    return (
        <div>
        <Helmet>
                <title>Taimoor Shahzada | Projects</title>
                <meta name="description" content="Web Designer, Front-End / React Developer and a Yotuber from Pakistan who create sites and applications that cause Aesthetes!" />
                <meta name="og:title" property="og:title" content="web projects" /> 
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="projects" />
                <meta property="og:site_name" content="Taimoor Shahzada" />
                <meta property="og:url" content="www.taimoorshahzada.com" />
                <meta name="keywords" content="HTML, CSS, JavaScript,ReactJs, React.Js, Awwwards Website, taimoorshahzada, Taimoor Shahzada Youtube, Taimoor Shahzada Youtube Channel ,taimoorshahzada.com, Taimoor, Shahzada, GSAP, Taimoor Shahzad, Taimoor Shahzada"/>
                <meta name="author" content="Taimoor Shahzada"/>
                <meta property="fb:app_id" content="https://www.facebook.com/taimooor.shahzada/" />
            </Helmet>
          <Header timeline={timeline_project} />
          <div className="project-page">
            <div className="my-projects">
              <h1 ref={el=> text1 =el}>My <br /> Projects</h1>
            </div>
            <div className="project-page-container">
              <div className="project-page-items">
                <div className="project-page-item">
                  <div className="project-page-item-number">
                    <p>01/09</p>
                  </div> 
                  <a href="/">
                  <div className="project-page-item-header">
                    <h1>Lorem, ipsum.</h1>
                    <p>Lorem, ipsum.</p>
                  </div>
                  <div className="project-page-item-image project-page-item-image1"></div>
                  <div className="project-page-item-footer">
                    <p>Lorem ipsum dolor sit amet.</p>
                    <img src={arrow} alt="" />
                  </div>
                </a>
                </div>
                <div className="project-page-item">
                  <div className="project-page-item-number">
                    <p>02/09</p>
                  </div>
                <a href="/">
                  <div className="project-page-item-header">
                    <h1>Lorem, ipsum.</h1>
                    <p>Lorem, ipsum.</p>
                  </div>
                  <div className="project-page-item-image project-page-item-image2"></div>
                  <div className="project-page-item-footer">
                    <p>Lorem ipsum dolor sit amet.</p>
                    <img src={arrow} alt="" />
                  </div>
                </a>
                </div>
                <div className="project-page-item">
                  <div className="project-page-item-number">
                    <p>03/09</p>
                  </div>
                <a href="/">
                  <div className="project-page-item-header">
                    <h1>Lorem, ipsum.</h1>
                    <p>Lorem, ipsum.</p>
                  </div>
                  <div className="project-page-item-image project-page-item-image3"></div>
                  <div className="project-page-item-footer">
                    <p>Lorem ipsum dolor sit amet.</p>
                    <img src={arrow} alt="" />
                  </div>
                </a>
                </div>
                <div className="project-page-item">
                  <div className="project-page-item-number">
                    <p>04/09</p>
                  </div>
                <a href="/">
                  <div className="project-page-item-header">
                    <h1>Lorem, ipsum.</h1>
                    <p>Lorem, ipsum.</p>
                  </div>
                  <div className="project-page-item-image project-page-item-image4"></div>
                  <div className="project-page-item-footer">
                    <p>Lorem ipsum dolor sit amet.</p>
                    <img src={arrow} alt="" />
                  </div>
                </a>
                </div>
                <div className="project-page-item">
                  <div className="project-page-item-number">
                    <p>05/09</p>
                  </div>
                <a href="/">
                  <div className="project-page-item-header">
                    <h1>Lorem, ipsum.</h1>
                    <p>Lorem, ipsum.</p>
                  </div>
                  <div className="project-page-item-image project-page-item-image5"></div>
                  <div className="project-page-item-footer">
                    <p>Lorem ipsum dolor sit amet.</p>
                    <img src={arrow} alt="" />
                  </div>
                </a>
                </div>
                <div className="project-page-item">
                  <div className="project-page-item-number">
                    <p>06/09</p>
                  </div>
                <a href="/">
                  <div className="project-page-item-header">
                    <h1>Lorem, ipsum.</h1>
                    <p>Lorem, ipsum.</p>
                  </div>
                  <div className="project-page-item-image project-page-item-image6"></div>
                  <div className="project-page-item-footer">
                    <p>Lorem ipsum dolor sit amet.</p>
                    <img src={arrow} alt="" />
                  </div>
                </a>
                </div>
                <div className="project-page-item">
                  <div className="project-page-item-number">
                    <p>07/09</p>
                  </div>
                <a href="/">
                  <div className="project-page-item-header">
                    <h1>Lorem, ipsum.</h1>
                    <p>Lorem, ipsum.</p>
                  </div>
                  <div className="project-page-item-image project-page-item-image7"></div>
                  <div className="project-page-item-footer">
                    <p>Lorem ipsum dolor sit amet.</p>
                    <img src={arrow} alt="" />
                  </div>
                </a>
                </div>
                <div className="project-page-item">
                  <div className="project-page-item-number">
                    <p>08/09</p>
                  </div>
                <a href="/">
                  <div className="project-page-item-header">
                    <h1>Lorem, ipsum.</h1>
                    <p>Lorem, ipsum.</p>
                  </div>
                  <div className="project-page-item-image project-page-item-image8"></div>
                  <div className="project-page-item-footer">
                    <p>Lorem ipsum dolor sit amet.</p>
                    <img src={arrow} alt="" />
                  </div>
                </a>
                </div>
                <div className="project-page-item">
                  <div className="project-page-item-number">
                    <p>09/09</p>
                  </div>
                <a href="/">
                  <div className="project-page-item-header">
                    <h1>Lorem, ipsum.</h1>
                    <p>Lorem, ipsum.</p>
                  </div>
                  <div className="project-page-item-image project-page-item-image9"></div>
                  <div className="project-page-item-footer">
                    <p>Lorem ipsum dolor sit amet.</p>
                    <img src={arrow} alt="" />
                  </div>
                </a>
                </div>
              </div>
            </div>
          </div>
          <GetInTouch />
          <Footer />
        </div>
    );
}

export default Projects
