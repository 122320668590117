import React, {useEffect, useRef} from 'react'
import Header from '../Header/Header'
import './About.css'
import flower from '../Assets/flower.svg'
import GetInTouch from '../GetInTouch/GetInTouch'
import Footer from '../Footer/Footer'
import {Helmet} from "react-helmet";
import gsap from 'gsap'
function About() {
    let text1 = useRef(null)
    let text2 = useRef(null)
    let image = useRef(null)
    let p1 = useRef(null)
    const timeline_about = gsap.timeline();

    useEffect(()=> {
        timeline_about.from([text1,text2],{
            duration: .8,
            y: 500,
            skewY: 10,
            stagger:{
                amount: .3
            }
        },"-=.5")
        timeline_about.from(image,{
            y: 300,
            duration: 1.5,
            delay: .2,
            opacity: 0
        },"-=.5")
        timeline_about.from(p1,{
            duration: .5,
            x: 200,
            skewX: 10,
            opacity: 0
        })
    })
    return (
        <div>
            <Helmet>
                <title>Taimoor Shahzada | About</title>
                
                <meta name="keywords" content="HTML, CSS, JavaScript,ReactJs, React.Js, Awwwards Website, taimoorshahzada, Taimoor Shahzada Youtube, Taimoor Shahzada Youtube Channel ,taimoorshahzada.com, Taimoor, Shahzada, GSAP, Taimoor Shahzad, Taimoor Shahzada"/>
                <meta name="author" content="Taimoor Shahzada"/>
                <meta name="description" content="Web Designer, Front-End / React Developer and a Yotuber from Pakistan who create sites and applications that cause Aesthetes!" />
                <meta name="og:title" property="og:title" content="Web Design and Portfolio" /> 
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="about" />
                <meta property="og:site_name" content="Taimoor Shahzada" />
                <meta property="og:url" content="www.taimoorshahzada.com" />
                <meta property="fb:app_id" content="https://www.facebook.com/taimooor.shahzada/" />
            </Helmet>
            <div className="about">
                <Header timeline={timeline_about}/>
                <div className="about-container">
                    <div className="container-text">
                        <div className="container-inner-text1">
                           <p ref={el=> text1 =el}>Taimoor</p> 
                        </div>
                        <div className="container-inner-text2">
                        <p ref={el=> text2 =el}>Shahzada</p> 
                        </div>
                    </div>
                    <div className="container-qoute container-qoute1" ref={el=> p1 =el}>
                    I CREATE SITES AND <br /> APPLICATIONS THAT CAUSE <br /> PLEASANT EMOTIONS
                    </div>
                    <div className="container-qoute container-qoute2">
                    I SPECIALIZE IN INTERACTION <br /> DESIGN, EXPERIENCE WEBSITES <br /> AND APPLICATIONS
                    </div>
                    <div className="container-image" ref={el=> image =el}></div>
                </div>
                <div className="about-container2">
                    <div className="about-container2-heading">
                        <div className="about-text-lower">
                            <p>Web Designer, </p>
                        </div>
                        <div className="about-text-lower">
                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Front-End </p>
                        </div>
                        <div className="about-text-lower">
                            <p> &nbsp;Developer &nbsp;&nbsp;&nbsp;  &</p>
                        </div>
                        <div className="about-text-lower">
                            <p>&nbsp;&nbsp;&nbsp;&nbsp;a Youtuber </p>
                        </div>
                    </div>
                    <div className="about-container2-p">
                        <p>I DO BOTH WEB-DESIGN AND DEVELOPMENT. <br /> SO THE FINAL RESULT ALWAYS TURNS   OUT  <br />THE WAY I INTENDED IT</p>
                        <p>I BELIEVE THAT EVERY SITE OR SERVICE SHOULD BE CREATED AESTHETICALLY PLEASING AND HAVE EXTRA VALUE FOR USERS</p>
                        <p className="about-container2-p-right">IF NECESSARY, MY SKILLS ALLOW <br /> YOU TO CREATE SITES COMPLETELY, <br /> CLOSING ALL THE CLIENT'S TASKS</p>
                        <p>I PREFER TO WORK AND CREATE WEBSITES WITH PEOPLE WHO APPROACH WORK AS THE BUSINESS OF THEIR WHOLE LIFE</p>
                        <p>NOW I AM A YOUTUBER, AND WORKING<br />  WITH THE AESTHETE IT AGENCY, ARE <br /> CREATING DIGITAL-PRODUCTS USING <br /> THIS APPROACH</p>
                    </div>
                    <div className="about-container2-heading2">
                        <div className="about-text-lower">
                            <p>What is my feature?</p>
                        </div>
                    </div>
                    <div className="about-container2-p">
                        <p className="about-container2-p-right">I'VE BEEN WORKING SINCE 2018. <br /> DURING THIS TIME, I REALIZED THAT <br /> I LIKE TO COMBINE FUNCTIONAL SOLUTIONS WITH <br /> AESTHETICS. THIS IS HOW I CREATE A PRODUCT <br /> THAT WILL BE USED WITH LOVE</p>
                        <p>I TRY TO USE ALL POSSIBLE TOOLS <br /> IN MY WORK TO ACHIEVE MAXIMUM RESULTS.  I <br />LOVE WORKING WITH 3D, ANIMATIONS, <br /> PHOTOGRAPHY, VIDEO, TYPOGRAPHY, COLORS AND<br /> UNUSUAL GRAPHIC SOLUTIONS</p>
                    </div>
                    <div className="about-container-flower">
                        <img src={flower} alt="" />
                    </div>
                </div>
                <div className="skill-set-about-p">
                    <p>SKILLS: <br /> <br />  HTML / CSS / JS <br />React.JS / GSAP / SASS / Three.JS <br /> Node.JS / Express / PHP / MySQL <br /> PHOTOSHOP / ILLUSTRATOR / PREMEIRE PRO / FIGMA</p>
                </div>
            </div>
            <div className="about-header">
                <div className="about-header-text">
                    <p>EVERYTHING I DO EVERY DAY IS AIMED AT ACHIEVING ONE GOAL - TO FILL PEOPLE'S DAILY LIVES WITH VISUAL AND FUNCTIONAL AESTHETICS 
                    <br />
                    <br />
                    THE OVERALL IMPRESSION OF THE PRODUCT CONSISTS OF DETAILS TO WHICH WE DEVOTE A LOT OF TIME
                    <br />
                    <br />
                    ELEGANT ANIMATION ON THE SITE OR A USER-FRIENDLY INTERFACE THAT YOU DON'T EVEN THINK ABOUT - THAT'S WHAT AESTHETICS IS ALL ABOUT</p>
                </div>
            </div>
            <div className="about-footer">
            <GetInTouch />
            <Footer />
            </div>
        </div>
    )
}

export default About
