import React, {useRef, useEffect} from 'react'
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import flower from '../Assets/flower.svg';
import curve from '../Assets/curve.svg'
import './Contact.css';
import { Helmet } from 'react-helmet';
import gsap from 'gsap';
function Contact() {
    let text1 = useRef(null)
    const timeline_contact = gsap.timeline();
    useEffect(()=> {
        timeline_contact.from(text1, {
            duration: 1,
            skewY: 10,
            y: 500,
            stagger: {
                amount: .4
            },
            opacity: 0
        },"-=1");
    })
    return (
        <div>
            <Helmet>
                <title>Taimoor Shahzada | Contact</title>
                <meta name="description" content="Web Designer, Front-End / React Developer and a Yotuber from Pakistan who create sites and applications that cause Aesthetes!" />
                <meta name="og:title" property="og:title" content="Web Design and Portfolio" /> 
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="contact page" />
                <meta property="og:site_name" content="Taimoor Shahzada" />
                <meta property="og:url" content="www.taimoorshahzada.com" />
                <meta name="keywords" content="HTML, CSS, JavaScript,ReactJs, React.Js, Awwwards Website, taimoorshahzada, Taimoor Shahzada Youtube, Taimoor Shahzada Youtube Channel ,taimoorshahzada.com, Taimoor, Shahzada, GSAP, Taimoor Shahzad, Taimoor Shahzada"/>
                <meta name="author" content="Taimoor Shahzada"/>
                <meta property="fb:app_id" content="https://www.facebook.com/taimooor.shahzada/" />
            </Helmet>
            <div className="contact-page">
                <Header timeline={timeline_contact}/>
                <div className="contact-page-container">
                    <h1 ref={el=> text1 =el}>Let's Talk about <br />the Project?
                    <img src={flower} alt="" /></h1>
                    <img src={curve} alt="" className="curve"/>
                </div>
                <Footer/>
            </div>
        </div>
    )
}

export default Contact
