import React from 'react'
import './Footer.css';
function Footer() {
    return (
        <div>
            <div className="footer">
                <div className="footer-container">
                    <div className="message-for-users">
                        <span>
                        Please COntact me in any way <br /> you like
                        </span>
                    </div>
                    <div className="social-links">
                        <div className="social-links-h">
                            Social
                        </div>
                        <div className="social-links-items">
                            <a  target="_blank" href="https://www.youtube.com/c/TaimoorShahzada" className="yt">Youtube</a>
                            <a  target="_blank" href="https://www.facebook.com/taimooor.shahzada/" className="fb">Facebook</a>
                            <a  target="_blank" href="https://www.instagram.com/taimoorshahzada_/" className="in">Instagram</a>
                            <a  target="_blank" href="https://www.linkedin.com/in/taimoor-shahzada-353b1120b/" className="lnk">Linkedin</a>
                            <a  target="_blank" href="https://github.com/taimoorshahzada" className="git">Github</a>
                            <a  target="_blank" href="https://twitter.com/Taimoor1319418" className="tw">Twitter</a>
                            <a  target="_blank" href="https://www.snapchat.com/add/m.ts1319418" className="sc">Snapchat</a>
                        </div>
                    </div>
                    <div className="social-links">
                    <div className="social-links-h">
                            Contact
                        </div>
                        <div className="social-links-items">
                        <a href="mailto:m.ts131941@gmail.com" className="mail">m.ts131941@gmail.com</a>
                        <a href="tel:+923024606680" className="phone">+92 302 4606680</a>
                        <a href="https://wa.me/923024606680" className="whatsapp">Whatsapp</a>
                        <a href="https://t.me/taimoorshahzada" className="telegram">telegram</a>
                    </div>
                        </div>
                    <div className="footer-menu social-links">
                        <div className="footer-menu-h social-links-h">
                            Other menu
                        </div>
                        <div className="footer-menu-links social-links-items">
                            <p className="footer-menu-links-items" className="footer-js-home">Home.</p>
                            <p className="footer-menu-links-items" className="footer-js-about">About.</p>
                            <p className="footer-menu-links-items" className="footer-js-blog">Blog.</p>
                            <p className="footer-menu-links-items" className="footer-js-project">Projects.</p>
                            <p className="footer-menu-links-items" className="footer-js-contact">Contact.</p>
                        </div>
                    </div>
                    <div className="footer-btn">
                           <a href="mailto:m.ts131941@gmail.com" className="footer-btn-a">Get Started!</a>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="left-footer-bottom">
                       <p>Pakistan</p>
                    </div>
                    <div className="right-footer-bottom">
                       <p> © 2021. Taimoor shahzada. ALL RIGHTS RESERVED</p>
                    </div>
                </div>
            </div>
            <div className="footer-ticker">
            <div className="footer-ticker-">
                <div className="footer-ticker-item"> &nbsp;— Taimoor Shahzada </div>
                <div className="footer-ticker-item"> &nbsp;— Taimoor Shahzada </div>
                <div className="footer-ticker-item"> &nbsp;— Taimoor Shahzada </div>
                <div className="footer-ticker-item"> &nbsp;— Taimoor Shahzada </div>
                <div className="footer-ticker-item"> &nbsp;— Taimoor Shahzada </div>
                <div className="footer-ticker-item"> &nbsp;— Taimoor Shahzada </div>
                <div className="footer-ticker-item"> &nbsp;— Taimoor Shahzada </div>
                <div className="footer-ticker-item"> &nbsp;— Taimoor Shahzada </div>
                <div className="footer-ticker-item"> &nbsp;— Taimoor Shahzada </div>
                <div className="footer-ticker-item"> &nbsp;— Taimoor Shahzada </div>
                <div className="footer-ticker-item"> &nbsp;— Taimoor Shahzada </div>
                <div className="footer-ticker-item"> &nbsp;— Taimoor Shahzada </div>
                <div className="footer-ticker-item"> &nbsp;— Taimoor Shahzada </div>
                <div className="footer-ticker-item"> &nbsp;— Taimoor Shahzada </div>
                <div className="footer-ticker-item"> &nbsp;— Taimoor Shahzada </div>
                <div className="footer-ticker-item"> &nbsp;— Taimoor Shahzada </div>
                <div className="footer-ticker-item"> &nbsp;— Taimoor Shahzada </div>
                <div className="footer-ticker-item"> &nbsp;— Taimoor Shahzada </div>
                <div className="footer-ticker-item"> &nbsp;— Taimoor Shahzada </div>
                <div className="footer-ticker-item"> &nbsp;— Taimoor Shahzada </div>
             
             </div>
        </div>

        </div>
    )
}

export default Footer
