import React, {useEffect, useRef, useState} from 'react'
import './Header.css';
import MenuIcon from '../Assets/menu.svg';
import { Link } from 'react-router-dom';
function Header({timeline}) {
    // const [delay, setDelay] = useState(0.2);
    let li1 = useRef(null);
    let li2 = useRef(null);
    let li3 = useRef(null);
    let li4 = useRef(null);
    let li5 = useRef(null);
    let li6 = useRef(null);
//     window.addEventListener("load", (e) => {
//         setDelay(3.4);
//     })
//     setTimeout(function(){
//         setDelay(0.2); 
//    }, 10000);
//    console.log
    useEffect(()=> {
        timeline.from([li1,li2,li3,li4,li5,li6],{
            opacity: 0,
            duration: 1,
            delay: .2,
            y: 20,
            stagger: {
                amount: .6
            }
        })
    });
    return (
        <header>
            <div id="logo"></div>
            <div className="toggle-menu">
                <img src={MenuIcon} alt="" />
            </div>
            <ul className="menu-items">
                    <li ref={el=>li1=el}><Link className="li" to="/">Home.</Link></li>
                    <li ref={el=>li2=el}><Link className="li" to="/about">About.</Link></li>
                    <li ref={el=>li3=el}><Link className="li" to="/blogs">Blog.</Link></li>
                    <li ref={el=>li4=el}><Link className="li" to="/projects">Projects.</Link></li>
                    <li ref={el=>li5=el}><Link className="li" to="/Contact">Contact.</Link></li>
                    <li ref={el=>li6=el}><a className="li" target="_blank" href="https://www.youtube.com/c/TaimoorShahzada">Youtube.</a></li>
                </ul>
        </header>
    )
}

export default Header
