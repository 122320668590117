import React, {useEffect, useRef} from 'react'
import './Blog.css'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import { Helmet } from 'react-helmet'
import gsap from 'gsap'
function Blog() {
    let text1 = useRef(null)
    const timeline_blog = gsap.timeline();
    useEffect(()=> {
        timeline_blog.from(text1, {
            duration: 1,
            skewY: 10,
            y: 500,
            stagger: {
                amount: .4
            },
            opacity: 0
        },"-=1");
    })
    return (
        <div>
            <Helmet>
                <title>Taimoor Shahzada | Blogs</title>
            </Helmet>
            <Header timeline={timeline_blog} />
            <div className="blog-message" ref={el=> text1 = el}>
                Blogs <br /> Coming <br /> Soon!
            </div>
            <Footer />
        </div>
    )
}

export default Blog
